<template>
	<div>
		<a-navbar-lesson/>
		<div id="main-content">
			<router-view/>
		</div>
	</div>
</template>

<script>
export default {
	components: {
		'a-navbar-lesson': () => import('@/plugins/appgamisfera/_layouts/lesson/a-navbar-lesson.vue'),
	},
	created() {
		document.getElementsByTagName("BODY")[0].setAttribute('style', 'padding-top: 56px')
	}
}
</script>
<style lang="scss" scoped>
	#main-content {
		min-height: calc(100vh - (223px + 56px));
	}
</style>

